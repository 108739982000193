<template>
  <Home></Home>
</template>

<script>
import { onMounted } from 'vue'
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  },
  setup(){
    
    onMounted(()=>{
    })
    
    return{
    }
  }
}
</script>

<style>
html, body {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden;
  background: #1c232a;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-family: -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, "Segoe UI", Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
}

#app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;

}
.alert {
  display: flex;
  width:100%;
  align-items: center;
  justify-content: center;
  color: white;
}
</style>
