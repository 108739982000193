<template>
  <div style="display: flex; justify-content: center; width: 100%;" >
  <div class="input-wrapper">
  <input  
       @keyup.enter="searchClick()"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      type="text" 
      placeholder="Busque aqui (ex: tap, peng)"/>
  <label @click="searchClick()" class="fa fa-search input-icon"></label>
</div>
    </div>
</template>
<script>
import {  } from "vue";
  
export default {
  name: 'SearchComponent',
  props: ['modelValue'],
  emits: ['update:modelValue','search'],
  
  setup(props, {emit}){
    
    function searchClick() {
      emit('search')
    }
    
    function alerta(msg) {
      console.log("ALERTA",msg)
      alert(msg);
    }
    
    return{
      alerta,
      searchClick
    }
  }
}
</script>

<style scoped>

  input[type=text] {
    display:block;
    margin: 0 0 0 auto;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid white;
    border-bottom: none;
    border-radius: 10px;
    color: #000;
    font-size: 16px;
    background: white;
    padding: 12px 60px 12px 15px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    transition: all .5s ease;
  }
  
  input[type=text]:focus {
    text-align: center;
    width: 100%;
    background: white;
    border: 1px solid white;
    border-bottom: none;
    outline:none;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    transition: all .5s ease;
    text-decoration: none !important;
  }

.input-icon{
  font-size: 26px;
  position: absolute;
  background: #eaeaea;
  border: none;
  text-align: right;
  padding: 8.7px;
  padding-left: 12px;
  padding-right: 12px;
  right: 0; 
  border-radius: 0px 10px 10px 0px;
  top: 0; 
}


.input-wrapper{
  width: 100%;
  position: relative;
}

 
/* Estilos para telas menores que 600px */
@media screen and (max-width: 600px) {
}

/* Estilos para telas entre 600px e 900px */
@media screen and (min-width: 600px) and (max-width: 900px) {
}

/* Estilos para telas maiores que 900px */
@media screen and (min-width: 900px) {

  input[type=text] {
    width: 100%;
    
  }

  input[type=text]:focus {
    width: 100%;

  }

  .input-wrapper{
    width: 70%;
  }
}
  
</style>
