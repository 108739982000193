<template>
  <div class="container">
    <div class="panel">
      <div class="content">
            <img loading="lazy" :src="imgReturn()" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, watch, reactive } from 'vue'

export default {
  name: 'HeroComponent',
  props:{
    data: Object
  },
  setup() {
    const images = ref([{src: 'https://res.cloudinary.com/dv5qzebcl/image/upload/c_scale,w_1024/v1679269051/blockhub/capanologo.jpg'},
                       {src: 'https://res.cloudinary.com/dv5qzebcl/image/upload/c_scale,w_640/v1679269051/blockhub/CapaHero.png'}]);

    const state = reactive({
      pageWidth: 0
    });

    const updateWidth = () => {
      state.pageWidth = window.innerWidth;
    }

    function imgReturn(){
      
        return this.state.pageWidth > 900 ? this.images[0].src : this.images[1].src
      
    }

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateWidth);
    });
    
    onMounted(async function () {
      updateWidth();
      window.addEventListener('resize', updateWidth);
    });

    watch('$route', () => {
      updateWidth();
    });

    function openURL(url){
      if(url)
        url = null
    }
    
    return {
      images,
      openURL,
      pageWidth: state.pageWidth,
      state,
      imgReturn
    };
  },
}
</script>

<style scoped>
.container {
    width: 100%;
    max-height: 100%;
/*     margin-bottom: 10px; */
}
  
.panel {
    width: 100%;
    max-height: 100%;
    padding-top: calc((3 / 4) * 95%);
    position: relative;
}

.content {
    max-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
  
.content img {
  width: 100%;
  display: block;
  max-height: 100%;
  object-fit: cover;
}

  
/* Estilos para telas menores que 600px */
@media screen and (max-width: 600px) {
}

/* Estilos para telas entre 600px e 900px */
@media screen and (min-width: 600px) and (max-width: 900px) {
}

/* Estilos para telas maiores que 900px */
@media screen and (min-width: 900px) {

  .container {
    width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-itens: center;
  }
    
  .panel {
    width: 70%;
    max-height: 100%;
    padding-top: calc((3 / 4) * 45%);
    position: relative;
  }
  
  .content {
    max-height: 100%;
    position: absolute;
    padding: 20px 0 20px 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

.content img {
  border-radius: 10px;
  object-fit: cover;
  object-position: 0% 11%;
}
}



  
</style>
