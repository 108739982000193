<template>
  <div class="container" style="">
    <img @load="onLoad" @error="onError" @click="openURL('https://pay.hotmart.com/M80788755S?off=h4dc1ijb&checkoutMode=10')"     src="https://res.cloudinary.com/dv5qzebcl/image/upload/c_scale,w_640/v1679268534/blockhub/Banner.png" />
    <div v-if="loading" class="spinner"></div>
  </div>
</template>

<script>
import { ref } from "vue"
  
export default {
  name: 'BannerComponent',
  props: {
  },
  setup(){

    const loading = ref(true)
    
    function openURL(url){
      if(url)
        url = null
        // window.open(url)
    }

    function onLoad() {
      this.loading = false;
    }
    
    function onError() {
      console.log('Error loading image');
    }
  
    return{
      openURL,
      loading,
      onLoad,
      onError
    }
  }
}
</script>

<style scoped>
.container{
  padding: 25px 5px 5px 5px; 
  position:relative; 
  display: inline-block;
  max-height: 165px;
}
  
img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 0.8s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
