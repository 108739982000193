<template>
  <div  v-for="cover in data" v-bind:key="cover"  class="container">
    <div class="container-2">
    <h3>{{cover.title}}</h3>
    
    <div
      class="card-container"
      :style=" { 'grid-template-columns': `repeat(${Math.ceil(filterCover(cover.content).length/2)},auto)` }"
    >
      <div
        v-for="card in filterCover(cover.content)"
        v-bind:key="card.id"
        v-bind:item="card"
        class="card"
      >
        <ImgCover :background="card.background" :url="card.link" />
      </div>
      </div>
    </div>
  </div>

</template>

<script>
import { } from 'vue'
import ImgCover from './ImgCover.vue'

export default {
  components:{
    ImgCover
  },
  name: 'CoverComponent',
  props: {
    data: Object
  },
  setup() {
    
    function filterCover(content){
      return content.filter(item => item.type == 'cover')
    }

    function openURL(url){
      if(url)
        window.open(url)
    }

    return {
      openURL,
      filterCover
    };
  },
}
</script>

<style scoped>
h3 {
  color: white;
  padding-left: 3px;
  margin: 20px 0 20px 0px;
}

.container{
  width: 100%;
  padding: 5px; 
  display: flex; 
  justify-content: center; 
  flex-direction: row; 
  align-itens: center;
}

.container-2{
  flex-direction: column; 
  display: flex; 
  width: 100%;
}

.card-container {
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  overflow-y: hidden;
  overflow-x: auto;
  grid-auto-flow: dense;
  padding-bottom: 10px;
  touch-action: none pan-x;
}

.card {
  width: calc(100px * 1.5);
  height: calc(
    calc(105px * 1.5) * (9 / 16)
  );
  border-radius: 10px;
  position: relative;
  display: inline-block;
}

.card-container::-webkit-scrollbar {
  display: none;
}

/* Estilos para telas menores que 600px */
@media screen and (max-width: 600px) {

}

/* Estilos para telas entre 600px e 900px */
@media screen and (min-width: 600px) and (max-width: 900px) {
}

/* Estilos para telas maiores que 900px */
@media screen and (min-width: 900px) {

.container{
  width: 100%;
  padding: 0px; 
  display: flex;
  justify-content: center;
  align-itens: center;
}

.container-2{
  flex-direction: column; 
  display: flex; 
  width: 70%;
}
  
.card-container {
  width: 100%;
  justify-content: start;
  align-itens: start;
}

.card {
  width: calc(160px * 1.5);
  height: calc(calc(165px * 1.5) * (9 / 16));
  border-radius: 10px;
  position: relative;
  display: inline-block;
}

}
  
</style>
