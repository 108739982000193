<template>
  <img :src="background" @click="openURL(url)" loading="lazy" @load="onLoad" @error="onError"/>
  <div v-if="loading" class="spinner"></div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'ImgComponent',
  props: {
    background: String,
    url: String
  },
  setup() {
   
    const loading = ref(true)
    
    function openURL(url){
      if(url)
        window.open(url)
    }

    function onLoad() {
      this.loading = false;
    }
    
    function onError() {
      console.log('Error loading image');
    }

    return {
      openURL,
      onLoad,
      onError,
      loading
    };
  },
}
</script>

<style scoped>

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;

}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 0.8s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
