<template>
  <Hero v-show="!componentView" :data="[{}]"></Hero>
  <div :class="{ navbar: componentView }">
    <div style="padding: 0px 5px 0px 5px;">
      <div style="background: #1c232a; border-radius: 0 0 10px 10px;">
        <div v-show="componentView" style="">
          <button class="button-back" @click="back()"><i class="fa  fa-arrow-left"></i></button>
        </div>
        <Search v-model="search" @search="searchFilter()"></Search>
      </div>
    </div>
  </div>
<!--   <Banner v-show="!componentView"></Banner> -->
  <Banner v-show="false"></Banner>
  <div v-if="state.loadingCover" class="spinner"></div>
  <Cover :data="state.dataCover" v-if="!componentView"></Cover>
  <TextBox v-show="!componentView"></TextBox>
  <SearchContainer :data="state.dataSearch" v-show="componentView"></SearchContainer>

</template>

<script>
import axios from 'axios'
import Fuse from 'fuse.js'
import _ from 'lodash'

import Hero from './Hero.vue'
import Search from "./Search.vue"
import Banner from './Banner.vue'
import Cover from './Cover.vue'
import TextBox from './TextBox.vue'
import SearchContainer from './SearchContainer.vue'

import { ref, reactive, onMounted } from 'vue'

export default {
  name: 'HomeComponent',
  components: {
    Hero,
    Search,
    Banner,
    Cover,
    TextBox,
    SearchContainer
  },
  props: {
    msg: String
  },
  setup() {
    let fuse = null
    const search = ref()
    const state = reactive({
      data: [],
      dataSearch: [],
      dataCover: [],
      dataHero: [],
      loadingCover: true
    })
    const componentView = ref(false)

    async function fetchData() {
      try {
        const response = await axios.get('/notion');
        // const response = await axios.get('https://BlockHub.juarezsoares.repl.co/notion');
        state.loadingCover = false
        return response.data;
      } catch (error) {
        console.log(error);
      }
    }

    function searchFilter() {
      if (this.search) {
        this.state.dataSearch = fuse.search(this.search).filter(el => el.score <0.2)
        console.log("SEARCH", this.state.dataSearch)
        this.componentView = true
        document.body.scrollTop;
        document.documentElement.scrollTop;
        window.scroll(0, 0);
        window.scrollTo(0,0);
      }
    }

    function back() {
      this.componentView = false
      this.search = null
    }

    onMounted(async function () {
      const result = await fetchData();
      console.log(result)
      state.data = result

      const groupedDB = _.groupBy(state.data, db => db.tags[0]);
      let DATA = Object.keys(groupedDB).map(el=>{
        let obj = {
          "type": "container", "title":el,
          "content": _.sortBy(groupedDB[el].map(el=>{return {type:"cover",...el}}), "priority")
        }
        return obj
      })
      
      console.log(DATA)
      
      state.dataCover = DATA
      state.dataHero = DATA

      let processedData = state.data.map(el=>{return {...el, keywords: el.keywords.replaceAll(", ",",").split(",")}})

      console.log("processedData",processedData)
      
      fuse = new Fuse(processedData, {
          includeScore: true,
          //fieldNormWeight: 0.3,
          //ignoreFieldNorm: true,
          minMatchCharLength: 3,
          distance: 1000,
          threshold: 0.2,
          keys: ['name', 'description', 'keywords'],
          /*keys: [
            'name','description',
            {
              name: 'keywords',
              weight: 5
            }
          ],*/
          //keys: ['keywords'],
          //ignoreLocation: true,
    })
    });

    return {
      search,
      searchFilter,
      componentView,
      back,
      state
    }
  }
}
</script>

<style scoped>
.button-search {
  padding: 12px;
  width: 100%;
  border-radius: 0 0 10px 10px;
  border: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.1;
  background-color: #04dcf6;
  color: #19242a;
}

.button-back {
  margin: 12px 0 12px 0;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 11px 15px;
  font-size: 24px;
  cursor: pointer;
  border-radius: 50%;
}

.button-back:hover {
  background-color: #04dcf6;
  border-radius: 50%;
  color: #1c232a;
}

.button-back:active {
  background-color: #04dcf6;
  border-radius: 50%;
}

.navbar {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
  
.spinner {
  position: absolute;
  top: 520px;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 0.8s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
  
</style>
