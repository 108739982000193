<template>
    <div class="container-top">
    <div class="container-top2">
    <div v-if="data.length<1" class="noresults">Nenhum resultado encontrado</div>
    <div v-for="card in data" v-bind:key="card" class="container">
      <div v-if="!card.item.private" @click="openURL(card.item.link)" class="container-content">
            <h3>{{card.item.name}}</h3>
            <div style="display:flex; flex-direction:row;">
              <p>{{card.item.description}}</p>
              <div v-if="card.item.background" class="container">
                <div class="panel">
                    <div class="content">
                        <img :src="card.item.background" />
                    </div>
                </div>
              </div>
              </div>
          </div>
      </div>
  </div>
      </div>
</template>

<script>
import { } from 'vue'

export default {
  name: 'SearchContainerComponent',
  props: {
    data: Array
  },
  setup(){

    function openURL(url){
      if(url)
        window.open(url)
    }

    return{
      openURL
    }
  }
}
</script>

<style scoped>
.container{
    width: 100%;
    margin-bottom: 5px;
}

.container-top{
  margin-top: 140px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-itens: center;
} 

.container-top2{
  padding: 5px;
  flex-direction: column; 
  display: flex; 
  width: 100%;
}
  
.panel {
    width: 100%;
    padding-top: calc((3 / 4) * 85%);
    position: relative;
}

.content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.container .container-content {
  color: #fff;
  left: 0;
  width: 100%;
  bottom: 0;
  padding: 1px 16px 24px;
  border-radius: 10px;
  box-sizing: border-box;
  background: black;
}

.container .container-content h2 {
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.1
}

.container .container-content p {
  margin: 8px 0 0;
  opacity: .65;
  font-size: 14px;
  width: 150%;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden
}

.content  img{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin: 5px;
}

.noresults {
  width:100%;
  text-align:center;
  color:white;
  font-size: 16px;
  
}

/* Estilos para telas menores que 600px */
@media screen and (max-width: 600px) {

}

/* Estilos para telas entre 600px e 900px */
@media screen and (min-width: 600px) and (max-width: 900px) {
}

/* Estilos para telas maiores que 900px */
@media screen and (min-width: 900px) {

.panel {
    display: none;
}
  
.container-top{
  margin-top: 140px;
  width: 100%;
  padding: 0px; 
  display: flex;
  justify-content: center;
  align-itens: center;
}

.container .container-content {
  color: #fff;
  left: 0;
  width: 100%;
  bottom: 0;
  padding: 1px 16px 24px;
  border-radius: 10px;
  box-sizing: border-box;
  background: black;
}

.container-top2{
  flex-direction: column; 
  display: flex; 
  width: 70%;
}

.container .container-content {
  color: #fff;
  left: 0;
  width: 100%;
}

.container .container-content p {
  -webkit-line-clamp: 10;
  margin: 2px 0 0;
}
}

</style>
